import React from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import PageNotFound from '@/dmPortal/ui/common/PageNotFound';
import withGlobalProps from '@/Framework/Router/Next/withGlobalProps';
import { CheckApplicationActivity } from '@/dmPortal/application/Layout/NavigationContext';
import GlobalAnalyticsContextProvider from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsContext';
import { pageRedirectTo } from '@/Framework/Router/Next/helpers';
import { AccountsTab, GlobalAnalytics, OrderbookTab, RoadshowTab, RouteList } from '@/dmPortal/ui/common/constants';

const mapPages = {
  [GlobalAnalytics.Analytics]: GlobalAnalyticsContextProvider,
  [AccountsTab.Accounts]: PageNotFound,
  [OrderbookTab.Orderbook]: PageNotFound,
};

const mapRedirects = {
  [RoadshowTab.Roadshow]: (id) => `${ RouteList.Roadshow }/${ id }/${ RoadshowTab.Edit }`,
};

export const getServerSideProps = withGlobalProps((context) => {
  const [, page, id] = context.req.url.split('/');

  const url = mapRedirects[page]?.(id);

  if (url) {
    return pageRedirectTo(url);
  }

  context.res.statusCode = 404;

  return { props: {} };
});

export default (): JSX.Element => {
  const { asPath } = useRouter();
  const page = asPath.split('/')[1];

  const Component = mapPages[page];

  if (Component) {
    return <Component />;
  }

  return (
    <CheckApplicationActivity>
      <PageNotFound />
    </CheckApplicationActivity>
  );
};
